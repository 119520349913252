//App.js

import React, { useState, useEffect } from "react";
import Chat, { Bubble, useMessages } from "@chatui/core";
import "@chatui/core/dist/index.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypePrism from "rehype-prism-plus";
import "prismjs/themes/prism-okaidia.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./App.css";

const App = () => {
  const { messages, appendMsg, setTyping } = useMessages([]);
  const [lastUserMessageTime, setLastUserMessageTime] = useState(0);
  const [lastAISendTime, setLastAISendTime] = useState(0);
  const [copiedStates, setCopiedStates] = useState({});

  const handleSend = async (type, val) => {
    if (type === "text" && val.trim()) {
      const currentTime = Date.now();
      // Enforce time rate limits
      if (currentTime - lastUserMessageTime < 10000 || currentTime - lastAISendTime < 3000) {
        // 等待直到时间限制结束再继续发送消息
        setTimeout(() => handleSend(type, val), Math.max(10000 - (currentTime - lastUserMessageTime), 3000 - (currentTime - lastAISendTime)));
        return; // Exit if time limit constraints are not met
      }
      setLastUserMessageTime(currentTime);
      // Truncate message if above character limits
      const truncatedVal = val.slice(0, 4000); // Current message character limit
      appendMsg({
        type: "text",
        content: { text: truncatedVal },
        position: "right",
      });
      setTyping(true);
      const messageHistory = messages.slice(-6).map((msg, index) => ({
        role: msg.position === "left" ? "model" : "user",
        parts: [{ text: msg.content.text.slice(0, 1000) }], // Truncate historical messages
      }));
      // Add the current user's input to the conversation history
      messageHistory.push({
        role: "user",
        parts: [{ text: truncatedVal }],
      });
      console.log("Formatted Message History Sent to AI: ", JSON.stringify(messageHistory, null, 2));
      try {
        const response = await fetch("https://ai-gemini.businessesai.net/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            model: "10",
            userContent: messageHistory,
            prompt: "EXP",
          }),
        });
        const data = await response.json();
        if (data && data.candidates && data.candidates.length > 0) {
          const replyText = data.candidates[0].content.parts.map((part) => part.text).join(" ");
          appendMsg({
            type: "text",
            content: { text: replyText },
            position: "left",
          });
          setLastAISendTime(Date.now());
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        appendMsg({
          type: "text",
          content: { text: "There was an error processing your request." },
          position: "left",
        });
      } finally {
        setTyping(false);
      }
    }
  };

  const getTextFromChildren = (children) => {
    // 使用递归函数从children中提取字符串
    const extractText = (nodes) => {
      if (!nodes) return "";
      if (typeof nodes === "string") return nodes;
      if (Array.isArray(nodes)) {
        return nodes.map((node) => extractText(node)).join("");
      }
      return extractText(nodes.props.children);
    };
    return extractText(children);
  };

  const handleCopySuccess = (id) => {
    // 设置当前ID为true，表示已复制
    setCopiedStates((prev) => ({ ...prev, [id]: true }));
    // 2秒后将当前ID复位
    setTimeout(() => {
      setCopiedStates((prev) => ({ ...prev, [id]: false }));
    }, 2000);
  };

  const renderMessageContent = (msg, msgId) => {
    const { content } = msg;
    return (
      <Bubble>
        <div className="markdown-container">
          <ReactMarkdown
            children={content.text}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypePrism]}
            components={{
              code({ node, inline, className, children, ...props }, index) {
                const match = /language-(\w+)/.exec(className || "");
                const textToCopy = getTextFromChildren(children);
                const id = `code-${msgId}-${index}`;
                return !inline && match ? (
                  <div style={{ position: "relative" }}>
                    <CopyToClipboard text={textToCopy} onCopy={() => handleCopySuccess(id)}>
                      <button style={{ position: "absolute", right: "5px", top: "5px" }}>Copy</button>
                    </CopyToClipboard>
                    <pre className={className} {...props}>
                      <code>{children}</code>
                    </pre>
                    <div style={{ position: "absolute", right: "5px", top: "-20px", display: copiedStates[id] ? "block" : "none" }}>Copied!</div>
                  </div>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
              table({ children, ...props }, index) {
                const textToCopy = getTextFromChildren(children);
                const id = `table-${msgId}-${index}`;
                return (
                  <div style={{ position: "relative" }}>
                    <CopyToClipboard text={textToCopy} onCopy={() => handleCopySuccess(id)}>
                      <button style={{ position: "absolute", right: "5px", top: "5px" }}>Copy</button>
                    </CopyToClipboard>
                    <table {...props}>{children}</table>
                    <div style={{ position: "absolute", right: "5px", top: "-20px", display: copiedStates[id] ? "block" : "none" }}>Copied!</div>
                  </div>
                );
              },
            }}
          />
        </div>
      </Bubble>
    );
  };

  return (
    <Chat
      navbar={{ title: "Business AI Assistant" }}
      messages={messages}
      renderMessageContent={(msg, index) => renderMessageContent(msg, index)}
      onSend={handleSend}
    />
  );
};

export default App;
